<template>

<app-content :loading="is.loading && !is.initialised">

	<app-content-head title="Notifications" subtitle="See the latest happenings relating to you and your buddies convention." :tabs="tabs" >

	</app-content-head>

	<app-content-body :loading="is.loading && is.initialised" :is-empty="!collection.length" placeholder="No notifications found.">

		<app-notification v-for="item in collection" :key="item.id" :item="item" :is-linked="true" :as-notification="true" :last-read="$store.getters['session/date/notify']" />

	</app-content-body>

</app-content>

</template>

<script>

import mixCollection from '@/mixin/collection'

export default {

	mixins: [mixCollection],

	data: function() {

		return {
			pagination: false,
			endpoint: 'convention/notifications'
		}

	},

	computed: {

		tabs: function() {

			return [
				{ name: 'Convention.Notifications', text: 'Personal' },
				{ name: 'Convention.Notifications.Buddies', text: 'Buddies' }
			]

		}

	}

}

</script>

<style scoped>

</style>